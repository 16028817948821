@use "../../Color" as *;

.device-first-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dashboard-first-step-space {
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;
  }
  .first-step-card {
    margin-top: 30px;
    width: 50%;
  }
  .dashboard-first-step-row-label {
    color: $device-label;
    font-size: 21px;
  }
}

.customer-arrow-wrapper {
  .customer-arrow {
    position: absolute;
    top: 120px;
    right: 100px;
  }
}

@media screen and (max-width: 430px) {
  .device-first-step-wrapper {
    .first-step-card {
      width: 95%;
      margin-bottom: 30px;
    }
  }
}
