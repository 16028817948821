@use "./Color" as *;
@use "./Font" as *;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast--error {
  background-color: $danger-active !important;
}

.Toastify__toast--success {
  background-color: $success !important;
}

.Toastify__toast--warning {
  background-color: $warning !important;
}

.Toastify__toast--default {
  background-color: #4dd2f0 !important;
}

.Toastify__toast-body {
  color: $white-color;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 0px !important;
  margin: 0px !important;
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-bg-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: $smallest-border-radius;
}

p {
  margin-bottom: 0;
}
