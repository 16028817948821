@use "../../Color" as *;

.dashboard-notification-wrapper {
  background-color: $white-color;
  padding: 20px 30px;
  border-radius: $border-radius;
}

.notification-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-text-wrapper {
  display: flex;
  flex-direction: column;
}

.notification-icon-wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.close-notification-wrapper {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
}

.close-notification-wrapper-trial {
  position: relative;
  left: 100px;

  svg {
    cursor: pointer;
  }
}

.orange-notification-wrapper {
  background-color: $orange-color;
  padding: 8px 0px 8px 0px;
  text-align: center;
  color: $white-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.sales-card-stat-row-wrapper {
  margin-top: 5px;

  .sales-card-stat-row-second-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .sales-card-stat-column {
    @include flex-center();
  }

  .sales-card-stat-first {
    color: $success;
    font-size: 20px;
  }

  .sales-card-stat-first-text {
    color: $success;
    font-size: 20px;
    width: 100px;
    text-transform: capitalize;
  }

  .sales-card-stat-day {
    width: 100px;
    text-transform: capitalize;
    color: $secondary-text;
    font-size: 13px;
  }
}

.totals {
  color: $secondary-text;
  font-size: 13px;
}

.activity-row-flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @include full-width;
  gap: 20px;
}

.ant-list .ant-list-item {
  padding: 5px 0;
}

.dashboard-activity-card-row:hover {
  color: $link-hover;
}

.dashboard-activity-table {
  margin-top: 20px;

  .ant-table-thead > tr > th {
    font-weight: bolder;
    color: $primary-text !important;
    font-size: 14px;
  }
}

.right-card-data-display {
  display: block;
  text-align: end;
  width: 300px;
}

.products-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.card-row {
  .ant-space-item {
    width: 35%;
  }

  .ant-space-item:first-child {
    width: 40%;
  }

  .ant-space-item:nth-child(2) {
    width: 25%;
  }
}

.card-row .ant-space-gap-row-small {
  row-gap: 10px !important;
}

.total-row-card-sale {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 106%;
}

.dashboard-sales-card-stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.dashboard-chart-collapsed {
  width: 400px !important;
}

.dashboard-chart {
  width: 320px !important;
  height: 200px !important;
}

.dashboard-sales-card-wrapper {
  width: 40%;
}

.total-row-card-sale-first {
  width: 120px;
}

.card-row-for-print {
  color: $dashboard-label;
  font-size: 12px;
}

.view-print-title {
  color: $secondary-text;
  font-weight: 500 !important;
}

.line-color {
  color: $dashboard-label;
}

.card-row-for-print:hover {
  color: $link-hover;
  cursor: pointer;
}

.dashboard-table-first {
  a {
    color: $secondary-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: block;
  }

  .ant-table-row:first-child {
    color: $success-text-color;
    font-size: 20px;

    a {
      color: $success-text-color;
      font-size: 20px;
    }
  }

  .ant-table-row:not(:first-child):not(:nth-child(2)) {
    color: $dashboard-label;
  }

  .ant-table-row:nth-child(2) {
    color: $dashboard-label !important;
  }
}

.dashboard-table {
  .ant-table-body {
    scrollbar-width: none;
  }

  .ant-table .ant-table-tbody > tr > td {
    padding: 0px 5px;
  }

  .ant-table-body {
    padding-right: 10px;
  }

  .ant-table-thead > tr > th {
    padding: 16px 5px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }

  .ant-table-thead > tr > th {
    font-weight: 400;
  }

  .ant-table-thead > tr > th {
    border-bottom: none !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    height: 0;
  }

  .ant-table-row {
    height: 25px;
    font-size: 13px;
    color: $dashboard-label;
  }

  a {
    color: $dashboard-label;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: block;
  }

  a:hover {
    color: $link-hover;
  }

  .ant-table-row:nth-child(2) {
    color: $success-text-color;

    a {
      color: $success-text-color;
    }
  }
}

.right-card-print {
  padding: 20px;
}

.dashboard-first-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dashboard-first-step-space {
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .first-step-card {
    margin-top: 30px;
    width: 40%;
  }

  .dashboard-first-step-row-label {
    color: $dashboard-label;
    font-size: 21px;
    cursor: pointer;
  }

  .dashboard-first-step-row-label:hover {
    color: $link-hover !important;
  }
}

.notification-icon-parent {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .anticon:last-child {
    margin-bottom: 5px;
  }
}

.customer-date-row {
  font-size: 13px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
}

.customer-row {
  font-size: 13px;
  font-weight: 500;
}

.row-font-size-small {
  font-size: 13px;
}

.top-product-link {
  color: $secondary-text !important;
}

.top-product-link:hover {
  color: $link-hover !important;
}

.activity-list {
  overflow-y: auto;
  height: 100%;

  .ant-list-item {
    margin-right: 10px;
  }
}

.dashboard-table-text {
  color: $dashboard-label;
}

@media print {
  .right-card-print {
    font-size: 40px;

    .print-btn {
      display: none;
    }

    .right-card-data-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .card-row-for-print {
    font-size: 12px;
  }
}

@media screen and (max-width: 1725px) {
  .dashboard-chart-collapsed {
    width: 300px !important;
  }
}

@media screen and (max-width: 1758px) {
  .dashboard-chart-collapsed {
    width: 320px !important;
  }
  .dashboard-chart {
    width: 300px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 1684px) {
  .dashboard-chart {
    width: 250px !important;
  }
}

@media screen and (max-width: 1597px) {
  .dashboard-chart {
    width: 240px !important;
  }
}

@media screen and (max-width: 1553px) {
  .dashboard-chart {
    width: 210px !important;
  }
}

@media screen and (max-width: 1575px) {
  .dashboard-chart-collapsed {
    width: 270px !important;
  }
}

@media screen and (max-width: 1440px) {
  .dashboard-sales-card-wrapper {
    @include full-width;
  }

  .card-row .ant-space-item:nth-child(2) {
    width: 25%;
  }

  .card-row {
    .ant-space-item {
      width: 30%;
    }

    .ant-space-item:first-child {
      width: 45%;
    }
  }

  .dashboard-chart {
    width: 400px !important;
  }
}

@media screen and (max-width: 1360px) {
  .card-row {
    display: flex;
    flex-direction: column;

    .ant-space-item {
      @include full-width;
    }

    .ant-space-item:nth-child(2) {
      @include full-width;
    }
  }

  .card-row .ant-space-item:first-child {
    @include full-width;
  }

  .dashboard-sales-card-stat-row {
    flex-direction: row;
  }
}

@media screen and (max-width: 1200px) {
  .notification-icon-parent {
    justify-content: flex-start;
  }

  .notification-icon-wrapper {
    display: block;
  }

  .notification-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .notification-text-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1105px) {
  .card-row {
    .ant-space-item:first-child {
      @include full-width;
    }
  }

  .card-row {
    display: flex;
    flex-direction: column;

    .ant-space-item {
      @include full-width;
    }
  }

  .dashboard-sales-card-stat-row {
    flex-direction: row;
  }

  .dashboard-sales-card-wrapper {
    width: 40%;
  }

  .dashboard-chart {
    width: 300px !important;
  }

  .dashboard-chart-collapsed {
    width: 500px !important;
  }
}

@media screen and (max-width: 1030px) {
  .dashboard-sales-card-stat-row {
    flex-direction: row;
  }
}

@media screen and (max-width: 884px) {
  .dashboard-sales-card-stat-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 430px) {
  .dashboard-sales-card-stat-row {
    .table-wrapper {
      @include full-width;
    }
  }

  .dashboard-chart-collapsed {
    width: 300px !important;
  }

  .customer-date-row {
    width: auto;
  }

  .close-notification-wrapper-trial {
    left: 8px;
  }

  .dashboard-first-step-wrapper {
    .first-step-card {
      width: 95%;
    }
  }

  .orange-notification-wrapper {
    text-align: start;
    padding-left: 5px;
    padding-right: 15px;
  }
}
