.gps-map-wrapper {
  border-radius: 20px;
  overflow: hidden;
  .ymaps-2-1-79-copyright__content {
    margin-right: 50px !important;
  }
}

.gps-map-wrapper-collapsed {
  .ymaps-2-1-79-copyright__content {
    margin-right: 10px !important;
  }
}
