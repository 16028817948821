@use "../../../Color" as *;

@media print {
  @page {
    size: auto portrait;
    margin: 10px;
  }

  img {
    width: 30px !important;
  }

  .normal-print,
  .ant-layout-sider,
  .no-print,
  .ant-dropdown {
    display: none !important;
  }

  .thermal-print-page {
    display: block !important;
  }

  .print-media-layout {
    display: block !important;
  }

  @media print and (min-width: 103mm) {
    .normal-print {
      display: block !important;
      padding: 5px 50px;
    }
    .order-view-table-print {
      th {
        padding: 8px !important;
      }
    }
    .ant-table-wrapper
      .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      height: 3em;
    }
    .ant-table-row {
      height: 37px;
    }
    .thermal-print-page {
      display: none !important;
    }
  }
}
