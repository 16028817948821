@use "../../Color.scss" as *;

.tree-parent {
  .ant-space-item {
    @include full-width;
  }
}

.export-left-table {
  .ant-table-selection {
    .ant-table-selection-extra {
      left: 5px;
    }
  }
  .ant-table-thead {
    .ant-table-cell:nth-child(2) {
      padding-left: 20px !important;
    }
  }
}

.editable-dragable-table {
  .ant-table-body {
    overflow-y: auto !important;
  }
}
