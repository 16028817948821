@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin rotate($degrees) {
  transform: rotate($degrees);
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
}

@mixin flex-center($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin bordered($color, $width) {
  border: $width solid $color;
}

@mixin border-solid($color) {
  border: 1px solid $color;
}

@mixin border-solid-bottom($color) {
  border-bottom: 1px solid $color;
}

@mixin border-solid-top($color) {
  border-top: 1px solid $color;
}

@mixin border-solid-right($color) {
  border-right: 1px solid $color;
}

@mixin padding($padding-top-bottom, $padding-left-right: $padding-top-bottom) {
  padding: $padding-top-bottom $padding-left-right;
}

@mixin margin($margin-top-bottom, $margin-left-right: $margin-top-bottom) {
  margin: $margin-top-bottom $margin-left-right;
}

@mixin full-width {
  width: 100%;
}
