@use "../../../Color" as *;

.outside-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  .my-card {
    @include full-width;
  }
}

.login-card {
  width: 30%;
  box-shadow: 0 1px 1px $box-shadow-dark !important;
  border: 1px solid $border-color;
  .card-header {
    font-size: 14px;
  }
  .ant-card-body {
    padding: 20px !important;
  }
}

.registration-card {
  width: 35%;
  box-shadow: 0 1px 1px $box-shadow-dark !important;
  border: 1px solid $border-color;
  .card-header {
    font-size: 14px;
  }
  .ant-card-body {
    padding: 20px !important;
  }
}

.registration-card-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.registration-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.no-account-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-space-row {
  align-items: baseline;
  margin-top: 25px;
}

.card-header-forgot {
  font-size: 25px;
  margin-bottom: 1px;
  color: $text-bluish-color;
}

.card-header-text {
  color: $text-bluish-color;
}

.registration-finish-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
  margin-top: 50px;
}

.registration-finish-text {
  display: block;
  width: 60%;
  font-size: 20px;
  color: $registration-finish-text-color;
  text-align: center;
}

.registration-finish-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.registration-finish-text-second {
  display: block;
  font-size: 20px;
  color: $registration-finish-text-color;
  text-align: center;
}

.register-text-wrapper {
  text-align: center;
}

.select-row-register {
  .ant-space-item:last-child {
    width: 90%;
  }
}

.registration-status-pending-wrapper {
  @include full-width;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  .registration-status-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 50%;
    text-align: center;
    height: 50%;
  }
}

.phone-code-row {
  display: flex;
  align-items: center;
  .ant-space-item:first-child {
    width: 20%;
  }
  .ant-space-item:last-child {
    @include full-width;
  }
}

.registration-card-wrapper {
  @include full-width;
  .ant-card {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-card-body {
    width: 70%;
  }
}

.registration-card-wrapper {
  .ant-form-item-control {
    margin-top: 7px;
  }
}

@media screen and (max-width: 1400px) {
  .registration-card-wrapper {
    height: auto;
  }
}

@media screen and (max-width: 1300px) {
  .login-card {
    width: 40%;
  }
  .registration-card {
    width: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .login-card {
    width: 50%;
  }
  .registration-card {
    width: 50%;
  }
}

@media screen and (max-width: 750px) {
  .login-card {
    width: 80%;
  }
  .registration-card {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .login-card {
    @include full-width;
  }
  .registration-card {
    @include full-width;
  }
}

@media screen and (max-width: 480px) {
  .login-card {
    @include full-width;
  }
}
