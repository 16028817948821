@use "../../Color.scss" as *;

.menu-dropdown {
  color: $white-color;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 5px 25px;
  text-align: center;
  display: flex;
}

.menu-header-title {
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
