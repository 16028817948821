@use "./Color" as *;

.second-side-menu {
  height: fit-content;
  margin-top: 10px;
  border-radius: $smaller-border-radius;
  min-width: 250px;
  padding: 5px;
  .ant-menu-item {
    height: 55px;
    display: flex;
    align-items: center;
  }
  .ant-menu-item-selected {
    background-color: $diff-grey !important;
  }
}

.user-side-menu {
  .ant-menu-item {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.wallet-icon-circle {
  background-color: $wallet-icon-bg-color;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-select-default {
  .ant-select-selector {
    border: 1px solid #f1f1f1 !important;
    background: $white-color !important;
  }
  .ant-select-selection-placeholder {
    color: $text-blue;
  }
  .ant-select-arrow {
    color: $text-blue;
  }
  .ant-select-clear {
    color: $text-blue;
  }
  .ant-select-clear:hover {
    color: $text-blue;
  }
}

.ant-tree-treenode-selected {
  .ant-tree-node-content-wrapper:hover {
    color: $white-color !important;
  }
}

.ant-tree-directory {
  .ant-tree-title {
    margin-left: 10px;
    font-size: 15px;
  }
  .ant-tree-treenode {
    line-height: 35px;
  }
  .ant-tree-iconEle {
    .anticon {
      font-size: 20px;
    }
  }
  .ant-tree-switcher {
    display: none;
  }
}

.filter-selected {
  .ant-select-selector {
    color: #8870e6 !important;
    background: rgba(136, 112, 230, 0.1) !important;
    border: none !important;
  }
}

.filter-selected-multi {
  .ant-select-selector {
    color: #8870e6 !important;
    background: rgba(136, 112, 230, 0.1) !important;
    border: none !important;
  }
}

.input-range-picker {
  @include full-width;
}

.my-date-picker {
  @include full-width;
}

.filtered::after {
  content: "*";
}

.my-input {
  @include full-width;
}

.input-number {
  @include full-width;
}

.app-input {
  @include full-width;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}

.settings-btn {
  margin-left: 10px;
  @include full-width;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: transparent;
}

.ant-form-item {
  margin-bottom: 0;
}

.link-cursor {
  cursor: pointer;
}

.ant-pagination-total-text {
  position: absolute;
  left: 15px;
  font-size: 10px;
  color: $primary-text;
}

.main-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.main-content-wrapper {
  @include full-width;
  overflow-y: auto;
  height: 100%;
}

.main-content {
  padding: 10px;
  margin-bottom: 10px;
}

.divider-small {
  margin: 0px !important;
}

.ant-popover .ant-popover-inner {
  border-radius: $filter-select-border-radius;
}

.ant-tag {
  margin-inline-end: 0px;
  padding-inline: 15px;
  line-height: 2;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none !important;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "";
  // display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  content: "*";
  display: inline-block !important;
  margin-inline-start: 4px;
  font-size: 14px;
  line-height: 1;
  color: $input-required-red-color !important;
  visibility: visible;
}

.svg-arrow-rotate-90 {
  transform: rotate(90deg);
}

.svg-arrow-rotate-180 {
  transform: rotate(-90deg);
}

.ant-table-container::before {
  display: none;
}

.ant-form-item-label {
  margin-bottom: 7px !important;
}

.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-form-item-label
  > label {
  height: 17px;
}

.my-checkbox-form-item {
  .ant-form-item-control {
    max-height: 20px !important;
  }
  .ant-form-item-control-input {
    min-height: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .mobile-menu-btn {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
