@use "../../../Color" as *;

.dropdown-export {
  height: 40px;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  background-color: $dropdown-export-color;
  color: $white-color;
  transition: all 0.5s linear;
}

.dropdown-export:hover {
  cursor: pointer;
  background-color: $primary-color;
}

.order-table {
  .ant-table-row {
    cursor: pointer;
  }
}

@media screen and (max-width: 930px) {
  .stat-card-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
