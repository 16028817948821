@use "../../Color" as *;

.main-sider {
  .ant-layout-sider-children {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  .ant-menu-item {
    height: 60px;
    display: flex;
    align-items: center;
  }
}

.header-nav {
  font-size: 18px !important;
  span {
    color: $text-bluish-color;
  }
  &.ant-menu-horizontal {
    border-bottom: 1px solid transparent !important;
  }
}

.header-nav-row-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  left: 0;
  z-index: 2;
  @include full-width;
  margin-top: 20px;
  height: 77px;
  padding-left: 15px;
  padding-right: 15px;
}

.header-nav-row {
  border-radius: 16px;
  background-color: $white-color;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  width: 70%;
}

.sticky-active {
  box-shadow: inset 0 0 0 0.5px rgba(114, 111, 111, 0.2),
    0 0 15px $box-shadow-dark;
}

.sticky-inactive {
  box-shadow: none;
}
