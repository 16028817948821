@use "../../Color.scss" as *;

.my-segment-form {
  @include full-width;
  border-radius: 10px;
  label {
    width: 50%;
  }
  .ant-segmented-item {
    border-radius: $even-smaller-border-radius;
    &:after {
      border-radius: $even-smaller-border-radius !important;
    }
  }
}
