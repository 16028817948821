@use "../../../Color.scss" as *;

.space-row-input {
  align-items: flex-start;
  .ant-space-item {
    width: 50%;
  }
}

@media screen and (max-width: 430px) {
  .space-row-input {
    display: flex;
    flex-direction: column;
    .ant-space-item {
      @include full-width;
    }
  }
}
