@use "../../../Color" as *;

.select-row {
  .ant-space-item {
    width: 50%;
  }
}

.input-discount-customer {
  .ant-input-number-prefix {
    width: 165px;
    justify-content: center;
    @include border-solid-right($default-grey);
    margin-right: 10px;
  }
}

.customer-edit-card {
  width: 40vw;
}

.map-wrapper {
  height: 100%;
  width: 42vw;
}

.map-wrapper-inside {
  border-radius: $smaller-border-radius;
  overflow: hidden;
  @include full-width;
  height: 45%;
}

@media screen and (max-width: 1700px) {
  .map-wrapper {
    width: 41vw;
  }

  .ymaps-2-1-79-copyright__content {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1600px) {
  .map-wrapper-collapsed {
    width: 51vw;
  }

  .map-wrapper {
    width: 40vw;
  }
}

@media screen and (max-width: 1500px) {
  .map-wrapper {
    width: 39vw;
  }
}

@media screen and (max-width: 1460px) {
  .map-wrapper {
    width: 38vw;
  }
}

@media screen and (max-width: 1400px) {
  .map-wrapper {
    width: 37vw;
  }
}

@media screen and (max-width: 1330px) {
  .map-wrapper {
    width: 36vw;
  }
}

@media screen and (max-width: 1280px) {
  .map-wrapper {
    width: 35vw;
  }
}

@media screen and (max-width: 1230px) {
  .map-wrapper {
    width: 34vw;
  }
}

@media screen and (max-width: 1180px) {
  .map-wrapper {
    width: 33vw;
  }
}

@media screen and (max-width: 1140px) {
  .map-wrapper {
    width: 32vw;
  }
}

@media screen and (max-width: 1090px) {
  .map-wrapper {
    width: 31vw;
  }
}

@media screen and (max-width: 1050px) {
  .map-wrapper {
    @include full-width;
  }

  .map-wrapper-inside {
    height: 500px;
  }

  .ymaps-2-1-79-copyright__content {
    margin-right: 0px;
  }

  .customer-edit-card {
    @include full-width;
  }
}

@media screen and (max-width: 430px) {
  .customer-edit-card {
    @include full-width;
  }

  .ant-card .ant-card-body {
    padding: 14px;
  }

  .select-row {
    flex-direction: column;

    .ant-space-item {
      @include full-width;
    }
  }
}
