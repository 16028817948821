@use "../../../../Color" as *;

.customer-list-table {
  .ant-card-body {
    padding: 10px;
  }
}

.apply-action-btn {
  color: $white-color;
  background-color: $apply-color;
  height: 40px;
  @include full-width;
  border-radius: 12px;
}

.seconday-btn {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-search {
  width: 400px;
}

.user-list-customer-table-wrapper {
  margin-top: 10px;
  @include full-width;
}
