@use "../../Color" as *;

.pricing-header {
  font-size: 40px;
  font-weight: bold;
  color: $diff-purple;
  strong {
    color: $diff-purple-strong;
  }
}

.pricing-card-wrapper-package-outside {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.package-card-wrapper-outside {
  display: flex;
  justify-content: center;
  width: 85%;
  gap: 30px;
}

.pricing-subtitle {
  font-size: 16px;
  max-width: 550px;
  font-weight: 500;
  color: $subtitle;
  line-height: 24px;
}

.left-image {
  position: absolute;
  left: 40px;
}

.right-image {
  position: absolute;
  right: 92px;
}

.pricing-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  @include full-width;
  text-align: center;
  flex-direction: column;
  margin-top: 60px;
}

.ant-segmented {
  background: transparent !important;
}

.pricing-segmented-section {
  .ant-segmented .ant-segmented-item-label {
    line-height: 22px;
  }
  .my-segment-vertical .ant-segmented-item {
    margin-bottom: 20px;
    background-color: $white-color;
    transform: none;
    border-radius: 9px;
    width: 248px;
    border: 1px solid transparent;
    padding: 10px;
  }
  .my-segment-vertical .ant-segmented-item-selected {
    border: 1px solid $diff-purple-strong;
    box-shadow: 0 3px 10px $box-shadow-dark;
  }
  .my-segment-vertical .ant-segmented-thumb {
    background: transparent;
    box-shadow: none;
    padding: 0;
    transition: 0.1s;
  }
}

.card-item {
  @include full-width;
  .ant-space-item {
    width: 31%;
  }
  .ant-space-item:last-child {
    @include full-width;
  }
  .card-item-second {
    .ant-space-item {
      @include full-width;
    }
  }
}

.pricing-parent-div {
  @include full-width;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  background-color: $pricing-page-bg;
  padding: 40px;
  .pricing-inner-div {
    width: 75%;
    display: flex;
    gap: 15px;
  }
}

.pricing-segment-div {
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: 4px;
}

@media screen and (max-width: 1408px) {
  .pricing-card-wrapper-package-outside {
    .package-card {
      width: 21%;
    }
  }
}

@media screen and (max-width: 1224px) {
  .package-card-wrapper-outside {
    gap: 15px;
    width: 90%;
  }
  .pricing-card-wrapper-package-outside {
    .package-card {
      width: 25%;
    }
  }
}

@media screen and (max-width: 430px) {
  .package-card-wrapper-outside {
    margin-top: 40px;
    flex-direction: column;
    width: 80%;
  }
  .pricing-card-wrapper-package-outside {
    @include full-width;
  }
  .pricing-subtitle {
    max-width: 300px;
  }
  .pricing-card-wrapper-package-outside .package-card {
    @include full-width;
  }
  .package-card-price {
    @include full-width;
  }
  .package-pricing-card-enterprise {
    @include full-width;
  }
  .pricing-parent-div {
    padding: 0;
    .pricing-inner-div {
      @include full-width;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  .card-item {
    @include full-width;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .card-item .ant-space-item {
    @include full-width;
  }
  .pricing-segmented-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
}
