@use "../../../Color" as *;

.user-side-menu {
  margin-top: 5px;
  min-width: 170px;
  max-width: 200px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: $smaller-border-radius;
  border-bottom-right-radius: $smaller-border-radius;
  .ant-menu-title-content {
    display: inline-block;
    box-sizing: border-box;
    white-space: normal;
    line-height: 30px;
  }
  .ant-menu-item {
    padding: 10px;
  }
  .ant-menu-item-group {
    .ant-menu-item-group-title {
      opacity: 1;
      color: $text-blue !important;
      padding: 10px 4px;
    }
  }
  .ant-menu-item-selected {
    background-color: $diff-grey !important;
  }
}

.user-side-menu-header {
  background-color: $white-color;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 170px;
  max-width: 200px;
  margin-bottom: -20px;
  padding: 10px;
}

@media screen and (max-width: 430px) {
  .edit-btn-row {
    flex-wrap: wrap;
  }
}
