.icon-avatar-form-item {
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }
  .image-preview-container {
    display: flex;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
