@use "../../Color" as *;

.my-link {
  &.primary {
    color: $order;
    &:hover {
      color: $order;
    }
  }
  &.blue {
    color: $link-blue;
    &:hover {
      color: $link-blue;
    }
  }
  &.purple {
    color: $diff-purple-strong !important;
    &:hover {
      color: $diff-purple-strong !important;
    }
  }
}
