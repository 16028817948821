@use "../../Color" as *;

.main-menu {
  background-color: $primary-color;
}

.side-menu-item {
  display: flex !important;
  font-weight: bold;
  padding-left: 30px !important;
  color: $left-menu-text !important;
  &.ant-menu-item-selected {
    color: $white-color !important;
  }
}
