@use "../../../Color.scss" as *;

.yandex-autocomplete {
  @include full-width;
  .ant-select-selector {
    border-radius: 6px !important;
    min-height: 40px;
  }
  .ant-select-clear {
    background-color: transparent !important;
  }
  .anticon svg {
    width: 15px;
    height: 15px;
  }
}

.google-autocomplete {
  border: 1px solid $google-autocomplete-gray !important;
  padding: 8px 11px;
  @include full-width;
}

@media screen and (max-width: 480px) {
  .yandex-autocomplete {
    .ant-select-clear {
      width: 40px;
      height: 40px;
      user-select: auto !important;
      z-index: 2000 !important;
      position: relative;
      left: 230px;
      bottom: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
