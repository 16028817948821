@use "../../Color" as *;

.stat-card-wrapper {
  min-width: 300px;
  border-radius: $smaller-border-radius;
  .card-stat {
    font-weight: bold;
    .ant-statistic-content-suffix {
      color: $suffix-stat-color;
      font-size: 15px;
    }
  }
}

.ant-statistic .ant-statistic-content {
  color: $primary-text;
}
