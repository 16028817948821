@use "../../Color" as *;
@use "../../Mixins" as *;

.my-button-action {
  @include flex-center();
  @include padding(14px, 10px);
  height: 0;
  width: 32px;
}

.action-gray {
  background-color: $default-gray;
}

.action-gray:hover {
  background-color: $default-gray-active !important;
}

.my-btn-confirm {
  @include flex-center();
  @include padding(14px, 10px);
  background-color: $not-confirm-color !important;
}

.my-btn-confirm:hover {
  background-color: $not-confirm-color-hover !important;
}

.my-btn-confirm-disabled {
  border-color: $default-gray !important;
  background: $default-gray !important;
}

.my-btn-confirm-disabled:hover {
  background: $default-gray-active !important;
}

.my-button-icon {
  display: flex;
}

.my-button-main-success {
  background: $not-confirm-color !important;
  box-shadow: none;
  min-width: 86px;
}

.my-button-main-success:hover {
  background: $not-confirm-color-hover !important;
  box-shadow: none;
}

.change-password-btn {
  background: $change-password-color;
  box-shadow: none;
}

.change-password-btn:hover {
  background: $change-password-hover-color;
}

.reset-btn {
  color: $white-color !important;
  background-color: $default-gray !important;
  border-color: $default-gray !important;
  transition: all 0.5 linear;
  min-width: 86px;
}

.reset-btn:hover {
  color: $white-color !important;
  background-color: $default-gray-active !important;
  border-color: $default-gray-active !important;
}

.ant-popconfirm-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.my-btn-blue {
  height: 50px;
  background: $btn-blue;
  color: $white-color;
  border: none;
  box-shadow: 0px 15px 30px rgba(1, 145, 250, 0.35);
  transition: all 0.3s ease-in-out;
  border-radius: 16px;
  min-width: 60px;
}

.my-btn-blue:disabled {
  background: $border-color;
}

.my-btn-blue:not(:disabled):hover {
  background: $btn-blue;
  box-shadow: none;
  color: $white-color;
}

.my-btn-purple {
  background: $order;
  height: 50px;
  border-radius: 9px;
  border: none;
}

.my-button-action-filter {
  @include flex-center();
  @include size(40px);
  background-color: $default-gray;
}

.my-button-action-filter:hover {
  background-color: $default-gray-active;
}

.confirmed-btn {
  cursor: not-allowed;
}

.text-confirm.ant-btn {
  padding: 0px;
  height: 20px;
  color: $default-black;
}

.text-confirm.ant-btn:hover {
  color: $link-hover;
}
