@use "../../../Color" as *;

.analytics-order-list-parent-wrapper {
  .analytics-stat-card-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    @include full-width;
  }
}

.label-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: $secondary-text;
}

.chart-info {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.value-name {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 1px;
}

.value-name-color1 {
  background-color: $chart-color-blue;
}

.value-name-color2 {
  background-color: $chart-color-orange;
}

.value-name-color3 {
  background-color: $chart-color-green;
}

.analytics-row-half {
  .ant-space-item {
    @include full-width;
  }
}

@media screen and (max-width: 430px) {
  .analytics-order-list-parent-wrapper .analytics-stat-card-row {
    flex-direction: column;
  }
  .analytics-stat-card-row {
    .ant-card {
      width: 100% !important;
    }
  }
}
