@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

/* Armenian fonts --------------------------------------------------------- */
@font-face {
  font-family: "Tahoma";
  src: url("../public/Fonts/arm/Tahoma.woff") format("woff"),
    url("../public/Fonts/arm/Tahoma.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArianAmu";
  src: url("../public/Fonts/arm/Arnamu.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
