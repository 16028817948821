@use "../../Color" as *;

.xml-download-btn {
  cursor: pointer;
  color: $xml-download-btn-color;
}

.upload-select-row {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings-select {
  width: 150px;
}

.settings-input {
  width: 195px;
}

.settings-switch {
  .ant-form-item-control-input-content {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

@media screen and (max-width: 430px) {
  .settings-select {
    @include full-width;
  }
  .settings-input {
    @include full-width;
  }
  .xml-row {
    align-items: flex-end;
    gap: 0;
  }
  .xml-download-btn {
    padding-left: -10px;
  }
  .tree-parent {
    .ant-transfer {
      display: flex;
      flex-direction: column;
    }
    .ant-transfer-operation {
      margin: 8px;
    }
  }
}
