@use "../../../Color" as *;

.order-view-table {
  display: flex;
  .ant-table {
    border-radius: $border-radius !important;
  }
  .ant-table-row:last-child {
    .ant-table-cell:last-child {
      border-bottom-right-radius: 0;
    }
    .ant-table-cell:first-child {
      border-bottom-left-radius: 0;
    }
  }
  .ant-table-thead {
    .ant-table-cell:first-child {
      border-start-start-radius: $border-radius !important;
    }
    .ant-table-cell:last-child {
      border-top-right-radius: $border-radius !important;
    }
  }
  .ant-table-summary tr:last-child {
    .ant-table-cell:first-child {
      border-bottom-left-radius: $border-radius;
    }
    .ant-table-cell:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}

.gps-not-included-wrapper {
  padding: 10px;
  margin-top: -20px;
  position: relative;
  z-index: 999;
  width: 270px;
}

.cards-wrapper {
  display: flex;
  @include full-width;
}

.left-card-label {
  color: $primary-text;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
}

.left-card {
  border-radius: 0px !important;
  border-start-start-radius: $border-radius !important;
  border-start-end-radius: $border-radius !important;
  margin-bottom: 15px;
  .ant-space {
    .ant-space-item {
      display: block;
      flex: none;
    }
  }
}

.column-render-title {
  color: $secondary-text;
  font-size: 12px;
}

.order-view-row {
  margin-top: 20px;
  padding: 10px;
  @include border-solid-bottom($white-gray-color);
  @include border-solid-top($white-gray-color);
  .ant-space-item:first-child {
    max-width: 300px;
  }
}

.page-second-section-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.my-title-wrapper {
  .my-title {
    font-weight: bold !important;
    color: $default-black !important;
    font-size: 16px !important;
  }
}

.order-view-table {
  .ant-spin-nested-loading {
    @include full-width;
  }
}

.order-view-second-section {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;

  .ant-card-body {
    padding: 8px 16px !important;
  }
}

.payment-view-second-section {
  border-radius: $border-radius !important;
  .ant-card-body {
    padding: 10px 16px !important;
  }
}

.table-wrapper-printer {
  @include full-width;
  font-size: 14px;
  border-collapse: separate;
  &.half {
    tr {
      display: flex;
      td {
        flex-basis: 50%;
      }
    }
  }
  img {
    object-fit: contain;
  }
  tbody {
    @include full-width;
    margin-top: 10px;
    display: block;
    &.total tr {
      display: flex;
      justify-content: flex-end;
      @include full-width;
    }
  }
  &-right {
    @include full-width;
    tr {
      display: flex;
      &:nth-child(even) {
        td:nth-child(1) {
          width: 20%;
        }
        td:nth-child(2) {
          width: 10%;
          text-align: center;
          padding: 0;
        }
        td:nth-child(3) {
          width: 30%;
          text-align: right;
        }
        td:nth-child(4) {
          width: 40%;
          text-align: right;
          padding-left: 0;
          padding-right: 20px;
        }
      }
    }
    tbody:last-child tr {
      td {
        &:first-child {
          width: 60%;
          text-align: right;
        }
        &:last-child {
          text-align: right;
          padding-right: 20px;
          width: 40%;
          padding-left: 0;
        }
      }
    }
  }
}

.table-wrapper-printer-right {
  tr {
    td {
      display: block;
      @include full-width;
    }
  }
}

.print-media-layout {
  display: none;
}
