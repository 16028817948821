@use "../../../Color" as *;

.package-card {
  border-radius: $bigger-border-radius;
  box-shadow: 0px 3px 25px $card-box-shadow !important;
  opacity: 1;
  backdrop-filter: blur(30px);
  width: 19%;
  text-align: center;
  color: $diff-blue;

  .ant-card-body {
    height: 100%;
  }

  .ant-space {
    height: 100%;
  }

  .package-name {
    margin-top: 10px;
    font-size: 21px;
  }

  .package-price {
    font-size: 45px !important;
    color: $diff-blue !important;
  }

  .package-btn {
    border-radius: $smaller-border-radius;
    font-weight: bold;
    @include full-width;
    background: $btn-blue !important;
    box-shadow: 0px 15px 30px $package-btn-box-shadow;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
    border: none;
  }

  .package-btn:hover {
    background: $btn-blue !important;
    box-shadow: none;
  }

  .package-text {
    font-size: 11px;
    color: $diff-blue !important;
  }

  .ant-card {
    padding: 23px;
  }

  .package-per-month {
    white-space: nowrap;
    font-style: italic;
    font-size: 12px;
  }
}

.package-card-enterprise {
  background-image: linear-gradient(to bottom, #230c3e, #6313b1);
  color: $white-color;
  width: 19%;

  .ant-card-body {
    height: 100%;
  }

  .ant-space {
    height: 100%;
  }

  .package-name {
    color: $white-color;
    margin-top: 0px;
  }

  .package-price {
    color: $white-color !important;
    font-size: 14px !important;
  }

  .package-demo-btn {
    border-radius: $smaller-border-radius;
    font-weight: bold;
    @include full-width;
    background: $btn-blue !important;
    box-shadow: 0px 15px 30px $package-btn-box-shadow;
    transition: all 0.3s ease-in-out;
    border: none;
    margin-top: 0;
  }

  .package-demo-btn:hover {
    background: $btn-blue !important;
    box-shadow: none;
  }

  .package-btn {
    background: $package-btn-bg !important;
    font-weight: bold;
    @include full-width;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    border: none;
    margin-top: 5px;
  }

  .package-btn:hover {
    background: $diff-purple-strong !important;
  }

  .enterprise-text {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .package-price-enterprise {
    color: $white-color !important;
    line-height: 19px;
  }
}

.package-card-wrapper {
  display: flex;
  justify-content: center;
  @include full-width;
  gap: 30px;
  z-index: 2;
}

.ordersbook-logo-wrapper {
  background-color: $primary-color;
  margin-bottom: 20px;
  margin-top: 10px;
  @include full-width;
  padding: 5px;
}

.popular-tag-wrapper {
  @include full-width;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popular-tag {
  background-color: $popular-color;
  border-radius: 100px;
  text-transform: uppercase;
  height: 22px;
  color: $white-color;
  font-weight: bold;
  width: 100px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-card-wrapper-package {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
  margin-top: 200px;

  .pricing-header {
    font-size: 40px;
    font-weight: bold;
    color: $diff-purple;

    strong {
      color: $diff-purple-strong;
    }
  }

  .pricing-subtitle {
    font-size: 16px;
    max-width: 550px;
    font-weight: 500;
    color: $subtitle;
    line-height: 24px;
  }

  .left-image {
    position: absolute;
    left: 270px;
    z-index: 1;
  }
}

.ant-radio-group {
  @include full-width;
}

.modal-company-name {
  color: $company-name-color !important;
  text-shadow: 0 1px 0 $left-menu-text;
  padding-bottom: 15px;
}

.package-wrapper-logo {
  display: flex;
  background-color: $white-color;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -40px;
  left: 35%;
  border-radius: 70px;
  @include border-solid($package-border-color);
}

.package-modal-logo-wrapper {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-form-wrapper {
  @include full-width;
}

.pay-modal {
  .ant-modal-content {
    background-color: $modal-bg-color;
    padding: 30px 15px 0px 15px;
  }

  .ant-modal-header {
    background-color: $modal-bg-color;
  }

  .modal-company-name {
    box-shadow: 0px 1px 0px $white-color;
    @include border-solid-bottom($package-border-color);
    display: block;
    @include full-width;
    padding-bottom: 15px;
    font-weight: bold !important;
    margin-top: 2px;
  }

  .modal-second-company-name {
    text-shadow: 0 1px 0 $left-menu-text;
    color: $company-name-color !important;
    @include border-solid-bottom($company-modal-border-bottom);
    display: block;
    @include full-width;
    padding-bottom: 5px;
    font-weight: bold !important;
  }

  .info-wrapper {
    @include border-solid($package-border-info-color);
    @include full-width;
    display: block;
    border-radius: 4px;
    color: $company-name-color;
    text-shadow: 0 1px 0 $white-color;
  }

  .payment-type-text {
    @include border-solid-bottom($package-border-info-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 18px;
    width: 89%;
    color: $company-name-color !important;
    text-shadow: 0 1px 0 $white-color;
    background-color: $modal-bg-color;
    position: absolute;
    top: 152px;
    left: 16px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .pay-form {
    margin-top: 10px;
    margin-bottom: 10px;
    @include border-solid($package-border-color);
    box-shadow: 0px 1px 0px $white-color;
    border-radius: 4px;
    background-color: $white-color;
    padding: 15px;
    @include full-width;
  }

  .app-select-radio {
    .ant-radio-wrapper {
      border: none;
      font-weight: bold;
      color: $primary-text;
    }

    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: $success;
      background-color: $success;
    }

    .ant-radio-wrapper:hover .ant-radio-wrapper,
    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: $success;
    }
  }

  .confirm-modal-btn-wrapper {
    padding: 5px 10px 20px 10px;
  }
}

.blocked-logout-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 50px;
}

.blocked-logout-row {
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24%;
}

.info-margin {
  margin-top: 3px;
  margin-right: 10px;
}

@media screen and (max-width: 1738px) {
  .package-card {
    width: 20%;
  }
}

@media screen and (max-width: 1669px) {
  .package-card {
    width: 22%;
  }
}

@media screen and (max-width: 1544px) {
  .package-card {
    width: 23%;
  }
  .package-card-wrapper {
    gap: 20px;
  }
}

@media screen and (max-width: 1380px) {
  .package-card-wrapper {
    gap: 20px;
  }

  .package-card {
    width: 22%;
  }
}

@media screen and (max-width: 1240px) {
  .package-card-wrapper {
    gap: 15px;
  }

  .package-card {
    width: 24%;
  }
}

@media screen and (max-width: 1180px) {
  .package-card .package-per-month {
    white-space: normal;
  }

  .package-card-wrapper {
    padding: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .package-card {
    width: 30%;
  }
}

@media screen and (max-width: 1071px) {
  .package-card {
    width: 30%;
  }

  .package-card-wrapper {
    gap: 15px;
  }

  .blocked-logout-wrapper {
    margin-top: 50px;
  }

  .pricing-card-wrapper-package {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1012px) {
  .package-card {
    width: 40%;
  }
}

@media screen and (max-height: 800px) {
  .pricing-card-wrapper-package {
    margin-top: 120px;
  }

  .blocked-logout-wrapper {
    margin-top: 40px;
  }
}

@media screen and (max-height: 768px) {
  .pricing-card-wrapper-package {
    margin-top: 150px;
  }

  .blocked-logout-wrapper {
    margin-top: 40px;
  }
}

@media screen and (max-height: 700px) {
  .pricing-card-wrapper-package {
    margin-top: 50px;
  }

  .blocked-logout-wrapper {
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .package-card {
    width: 70%;
  }

  .package-card-wrapper {
    display: flex;
    flex-direction: column;
  }

  .pricing-card-wrapper-package {
    height: fit-content;
  }

  .blocked-logout-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 20px;
  }

  .pricing-card-wrapper-package {
    .left-image {
      top: 1px;
    }
  }
}

@media screen and (max-height: 585px) {
  .pricing-card-wrapper-package {
    margin-top: 0px;
  }

  .blocked-logout-wrapper {
    margin-top: 30px;
  }
}

@media screen and (max-height: 530px) {
  .pricing-card-wrapper-package {
    margin-top: 0px;
    padding-bottom: 20px;
  }
}

@media screen and (max-height: 400px) {
  .pricing-card-wrapper-package {
    margin-top: 20px;
  }

  .blocked-logout-wrapper {
    margin-top: 30px;
  }
}

@media screen and (max-width: 450px) {
  .package-card {
    @include full-width;
  }
}

@media screen and (max-width: 375px) {
  .package-card {
    @include full-width;
  }

  .package-card-wrapper {
    display: flex;
    flex-direction: column;
  }

  .pricing-card-wrapper-package {
    margin-top: 0;
  }

  .blocked-logout-wrapper {
    padding-top: 0;
    padding-bottom: 30px;
    margin-top: 20px;
  }
}
