@use "../../Color" as *;

.page-header-main {
  margin-left: 8px;
  display: block;
}

.arrow-icon {
  .anticon {
    padding: 10px;
  }
}
